import { render, staticRenderFns } from "./SvoiHint.vue?vue&type=template&id=c736de44&scoped=true&"
import script from "./SvoiHint.vue?vue&type=script&lang=ts&"
export * from "./SvoiHint.vue?vue&type=script&lang=ts&"
import style0 from "./SvoiHint.vue?vue&type=style&index=0&id=c736de44&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c736de44",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvoiIcon: require('/usr/src/app/components/SvoiUI/SvoiIcon.vue').default})
