
import { defineComponent } from 'vue-demi'
import { SvoiHint } from '@svoi-ui/components/hint'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { BaseInputComponent } from './BaseInput'

export default defineComponent({
  name: 'SvoiInput',
  components: { SvoiHint, SvoiIcon },
  extends: BaseInputComponent,
  setup(props, ctx) {
    // @ts-expect-error
    const bindComponent = BaseInputComponent?.setup ? BaseInputComponent.setup(props, ctx) : {}

    return { ...bindComponent }
  }
})
