
import { defineComponent, PropType, computed } from 'vue-demi'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { Themes, ThemesEnum } from '@svoi-ui/interfaces/hint'
import { isString } from '@svoi-ui/shared/utils/guards'

export default defineComponent({
  name: 'SvoiHint',
  components: { SvoiIcon },
  props: {
    theme: {
      type: String as PropType<Themes | string>,
      default: ThemesEnum.qiwi,
      validator: (value: unknown) => {
        return (
          isString(value) &&
          [
            ThemesEnum.qiwi.toString(),
            ThemesEnum.figs.toString(),
            ThemesEnum.banana.toString(),
            ThemesEnum.chilli.toString(),
            ThemesEnum.potato.toString()
          ].includes(value)
        )
      }
    },
    icon: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const classes = computed(() => ({
      [`-theme-${props.theme}`]: true
    }))

    return { classes }
  }
})
