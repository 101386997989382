
import { defineComponent, isVue2, PropType } from 'vue-demi'
import { SvoiHint } from '@svoi-ui/components/hint'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { InputMask, mask } from '@svoi-ui/shared/directive'
import { BaseInputComponent } from './BaseInput'

export default defineComponent({
  // @ts-expect-error
  name: 'SvoiMaskedInput',
  components: { SvoiHint, SvoiIcon },
  directives: {
    mask: isVue2
      ? mask
      : {
          beforeMount: mask.bind,
          updated: mask.componentUpdated,
          unmounted: mask.unbind
        }
  },
  extends: BaseInputComponent,
  props: {
    ...BaseInputComponent.props,
    mask: {
      type: [String, Function, Array] as PropType<InputMask>,
      default: ''
    }
  },
  setup(props, ctx) {
    const bindComponent = BaseInputComponent?.setup ? BaseInputComponent.setup(props, ctx) : {}

    return { ...bindComponent }
  }
})
