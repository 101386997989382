import { ComponentPropsOptions, computed, defineComponent, isVue2, PropType, Ref, ref } from 'vue-demi'
import { Sizes, Type } from '@svoi-ui/interfaces/input'
import { isRefObject, isString } from '@svoi-ui/shared/utils/guards'

const modelProp: ComponentPropsOptions = isVue2
  ? {
      value: {
        type: [String, Number],
        default: ''
      }
    }
  : {
      modelValue: {
        type: [String, Number],
        default: ''
      }
    }

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const BaseInputComponent = defineComponent({
  name: 'BaseInput',
  props: {
    ...modelProp,
    type: {
      type: String as PropType<Type>,
      default: 'text',
      validator: (value: string) => {
        return ['text', 'password', 'number'].includes(value)
      }
    },
    size: {
      type: String as PropType<Sizes | string>,
      default: 'l',
      validator: (value: unknown) => {
        return isString(value) && ['l', 'm'].includes(value)
      }
    },
    label: {
      type: String,
      default: ''
    },
    /** @deprecated */
    hint: {
      type: String,
      default: ''
    },
    error: {
      type: String as PropType<string | Ref<string>>,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: Infinity
    },
    placeholder: {
      type: String,
      default: ''
    },
    tabindex: {
      type: Number,
      default: 0
    }
  },
  emits: ['input', 'change', 'focus', 'blur', 'on-click-icon', 'keyup', 'update:modelValue'],
  setup(props, { emit }) {
    const focus = ref(false)
    const inputRef = ref<HTMLInputElement>()

    const computedValue = computed({
      get() {
        // @ts-expect-error
        return isVue2 ? String(props.value) : String(props.modelValue)
      },
      set(value) {
        emit(isVue2 ? 'input' : 'update:modelValue', value)
      }
    })

    const classes = computed(() => {
      return {
        [`-size-${props.size}`]: true,
        '-focus': isRefObject(focus) && focus.value,
        '-filled': isRefObject<string>(computedValue) && computedValue.value.length > 0,
        '-disabled': props.disabled,
        '-error': props.error
      }
    })

    const computedType = ref(String(props.type))

    const onClickEye = () => {
      computedType.value = computedType.value === 'password' ? 'text' : 'password'
    }

    const iconEye = computed(() => (computedType.value === 'password' ? 'security/eye' : 'security/eye-slash'))

    const onActivate = () => {
      if (!props.disabled) {
        focus.value = true
        emit('focus')
      }
    }

    const onBlur = () => {
      focus.value = false
      emit('blur')
    }

    const onChange = (event: Event) => {
      emit('change', (event.target as HTMLInputElement).value)
    }

    const triggerFocus = () => {
      if (inputRef.value) {
        inputRef.value.focus()
      }
    }

    const triggerBlur = () => {
      if (inputRef.value) {
        inputRef.value.blur()
      }
    }

    const onClickIcon = () => {
      if (!props.disabled) {
        emit('on-click-icon')
      }
    }

    return {
      inputRef,
      computedValue,
      classes,
      focus,
      computedType,
      iconEye,
      onActivate,
      onBlur,
      onChange,
      onClickEye,
      onClickIcon,
      triggerFocus,
      triggerBlur
    }
  }
})
